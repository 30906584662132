.nsewdrag.drag {
  cursor: crosshair !important;
}

.plot-style {
  width : 100%;
  height: 100%;
}

.container__loading {
  height         : 100%;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}