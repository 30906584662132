//@import "~antd/dist/antd.compact.css";
@import "~antd/dist/antd.dark.less";

.App-Container {
  height: 100vh;
  width: 100vw;
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.App-Form {
  > * {
    margin-bottom: 0.5rem;
  }

  &.displayErrors {
    .form-control {
      input:invalid {
        border-color: red;
        & + span {
          visibility: visible;
        }
      }
    }
  }
  .form-control {
    input {
      height: 1.25rem;
      border: 1px solid #efefef;
      border-radius: 0.25rem;
      outline: none;
      & + span {
        visibility: hidden;
        font-size: 0.75rem;
      }
    }
  }

  .form-control {
    display: flex;
    flex-direction: column;
  }

  input[type="submit"] {
    height: 1.5rem;
    border: 1px solid #efefef;
    border-radius: 0.25rem;
    outline: none;
    background-color: #efefef;
  }
}

@primary-color: #e85630;